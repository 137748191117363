export const sanitizeSearchQuery = (_query: string) => {
  if (!_query || typeof _query !== 'string') return '';

  let query = _query.normalize('NFKC'); // Normalize Unicode to prevent homoglyph spoofing

  // Remove emails
  query = query.replace(
    /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[a-z]{2,}\b/gi,
    '',
  );

  // Remove HTML tags
  query = query.replace(/<[^<>]*>/g, '');

  // Remove URLs
  query = query.replace(/\b(?:https?:\/\/|www\.)\S+\b/gi, '');
  query = query.replace(/\b[a-z0-9.-]+\.[a-z]{2,}(?:\/\S*)?\b/gi, '');

  // Remove SQL keywords
  query = query.replace(
    /\b(?:SELECT|DROP|INSERT|DELETE|UPDATE|ALTER|CREATE|TRUNCATE|EXEC|UNION|SCRIPT)\b/gi,
    '',
  );

  // Remove JavaScript injection attempts
  query = query.replace(/\bjavascript:[^\s]+/gi, '');
  query = query.replace(/<script\b[^>]*>.*?<\/script>/gi, '');

  // Remove excessive whitespace and zero-width spaces
  query = query.replace(/\s+/g, ' ').trim();
  query = query.replace(/[\u200B-\u200D\uFEFF]/g, '');

  return query;
};
