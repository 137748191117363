import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import DefaultBanner from './Themes/DefaultBanner';
import { HotDealsProps } from './types';

dayjs.extend(utc);
dayjs.extend(tz);

const HotDeals = ({ closeMenu }: HotDealsProps) => (
  <DefaultBanner closeMenu={closeMenu} />
);

export default HotDeals;
