import Link from 'next/link';
import * as Icons from './Icons';

export default function AccessBarLogo() {
  return (
    <>
      <div className="mt-2 hidden hover:cursor-pointer lg:mr-10 lg:flex">
        <Link href="/" prefetch={false} legacyBehavior>
          <a
            aria-label="Link to Home"
            id="icon-home-desktop"
            className="relative"
          >
            <Icons.Logo />
          </a>
        </Link>
      </div>

      <div className="flex items-center justify-center hover:cursor-pointer lg:hidden">
        <Link href="/" prefetch={false} legacyBehavior>
          <a
            aria-label="Link to Home"
            id="icon-home-mobile"
            className="relative"
          >
            <Icons.LogoMobile />
          </a>
        </Link>
      </div>
    </>
  );
}
